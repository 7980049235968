.Payment-input{
  color: '#000';
  max-width: 500px;
  color: black;
  background-color:#eeeeee;
  padding: 6px 10px;
  border: none;
  display: inline-block;
  font-family: futura-book, sans-serif;
}
.Label-left{
  position: relative ;
  right: 5% ;
}
.Label-center{
  position:static;
  left:0;
}
.Save-banner{
  position: relative;
  width: 38%;
  bottom: 13%;
  left: 30%;
}
.Payment-monthly{
  position: relative;
  top: 1%;

}
.Payment-yearly{
  position: relative;
  bottom: 1%;
}
.Payment-description{
  width:70%;
  padding-top:20px;
  padding-bottom:20px;
  margin:0 auto 0 auto;
  font-size:13px;
  font-family:futura-book;
}

.linkprivacy{
  text-decoration:underline;
  color:#9ac8b9;
}


@media only screen and (max-height: 1600px){
	.footer-payment {
		position: relative;
	}
}
