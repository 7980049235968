$main-background: #f2f6f5;

.program-section-wrapper {
  overflow-x: auto;
}

.grey-background-container {
  width: 100%;
  height: 100%;
  background: $main-background;
  margin: 40px;
}

.grid-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 0.5fr 0.79fr 0.79fr;
  grid-template-areas:
    "todays-workout-snapshot this-week-snapshot"
    "todays-workout-snapshot recommended-snapshot"
    "recommended-classes-snapshot recommended-classes-snapshot"
    "recent-classes-snapshot recent-classes-snapshot"
    "favorite-classes-snapshot favorite-classes-snapshot"
    "history-snapshot history-snapshot"
    "noequipment-snapshot noequipment-snapshot";
  width: 100%;
  grid-gap: 15px;
  height: 100%;
}

.grid-container-whitelabel {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 2fr 1fr 1fr;
  grid-template-areas:
    "todays-workout-snapshot todays-workout-snapshot this-week-snapshot"
    "recommended-classes-snapshot recommended-classes-snapshot recommended-classes-snapshot"
    "recent-classes-snapshot recent-classes-snapshot recent-classes-snapshot"
    "favorite-classes-snapshot favorite-classes-snapshot favorite-classes-snapshot"
    "history-snapshot history-snapshot history-snapshot";
  width: 100%;
  grid-gap: 30px;
  height: 100%;
}

@media only screen and (max-width: 800px) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 0.75fr 0.25fr 0.75fr 0.75fr;
    grid-template-areas:
      "todays-workout-snapshot"
      "this-week-snapshot"
      "recommended-snapshot"
      "recommended-classes-snapshot"
      "recent-classes-snapshot"
      "favorite-classes-snapshot"
      "history-snapshot"
      "noequipment-snapshot";
    width: 100%;
    grid-gap: 30px;
    height: 100%;
  }
  .grid-container-whitelabel {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 0.75fr 0.72fr 0.72fr;
    grid-template-areas:
      "todays-workout-snapshot"
      "this-week-snapshot"
      "recommended-classes-snapshot"
      "recent-classes-snapshot"
      "favorite-classes-snapshot"
      "history-snapshot";
    width: 100%;
    grid-gap: 30px;
    height: 100%;
  }
}

.this-week-snapshot {
  grid-area: this-week-snapshot;
  box-shadow: 50%;
}

.active-snapshot {
  grid-area: active-snapshot;
}

.recommended-snapshot {
  grid-area: recommended-snapshot;
  align-self: flex-end;
}

.todays-workout-snapshot {
  grid-area: todays-workout-snapshot;
}

.recommended-classes-snapshot {
  grid-area: recommended-classes-snapshot;
  min-width: 0px;
}

.favorite-classes-snapshot {
  grid-area: favorite-classes-snapshot;
  overflow-x: auto;
}

.noequipment-classes-snapshot {
  grid-area: noequipment-snapshot;
  overflow-x: auto;
}
.history-snapshot {
  grid-area: history-snapshot;
  overflow-x: auto;
}

.recent-classes-snapshot {
  grid-area: recent-classes-snapshot;
  min-width: 0px;
  /*overflow-x: auto;*/
}

.container-content {
  width: 100%;
  background: white;
  border-radius: 4px;
  margin: auto;
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  // padding-bottom: 5px;
  padding: 1.5em;
}

.todays-workout-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.this-week-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

#daily-workout {
  display: flex;
}

.flex {
  display: flex;
  flex-direction: column;
}

.card-top-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.week-information {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  margin: 12px 0;
  padding: 12px 5px;
}

.card-top-bar-text {
  color: white;
}

.card-top-bar-text:hover {
  color: black;
  text-decoration: none;
}

.day-information {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
}

.circle-with-text-below {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#circular-progress-bar-container {
  width: 64px;
  // min-width: 60px;
  height: 64px;
  color: #828282;
  font-size: 16px;
  font-weight: bold;

  span {
    display: flex;
    margin-top: 5px;
  }
}

.circular-progress-bar {
  transform: scale(1.25);
}

//this week circles starts here

.this-week-circles-outer {
  display: flex;
  width: auto;
  height: auto;
  justify-content: space-evenly;
  // align-items: center;
}

.this-week-circles-inner {
  width: 110px;
  // box-shadow: 1px 1px 20px 0.5px #0ff;
}

.this-week-circles-labels {
  // width: 100%;
  display: flex;
  justify-content: space-evenly;

  > span {
    font-size: 20px;
    margin-left: 20px;
  }
}

.recommended-snapshot__information-container {
  width: 100%;
  margin: auto;
  text-align: center;
  // vertical-align: center;
}

p {
  margin: 10px;
}

.flex-spacer {
  flex: 1;
}

.information-container__program-detail {
  display: flex;
  font-size: 12px;
  color: #828282;
}

.information-container__header {
  margin: 8% 0;
}

.pure-u-1-2 {
  display: flex;
}

.pure-u-1-2-p {
  margin: 0;
  padding: 0;
  font-size: 0.98em;
}

.--vid {
  top: 10px;
}

.classes-title {
  display: block;
  width: 100%;
  text-align: left;
  padding-left: 1rem;
}

.class-container {
  margin: auto;
  // width: 30em;
}

.program-info {
  padding: 0;
  margin: 0;
}

.program-rec-header a {
  font-size: 18px;
}

.weekly-totals {
  display: flex;
  flex-direction: row;
  margin: 4px;
  justify-content: center;
}

.status-container {
  display: flex;
  flex-direction: row;
  margin: 8px;
  font-size: 14px;
}

.status-container-week {
  display: flex;
  flex-direction: row;
  margin: 4px;
  font-size: 14px;
}

.status {
  border-radius: 50%;
  height: 24px;
  width: 24px;
  border: 2px solid black;
  margin-right: 4px;
}

.this-week-status {
  border-radius: 50%;
  height: 12px;
  width: 12px;
  border: 6px solid black;
  margin: 5px;
}

.scheduled {
  border: 2px solid #9ac8b9;
  font-size: 15px;
}

.skipped {
  border: 6px solid #7d92a5;
  background-color: #7d92a5;
}

.completed {
  border: 2px solid #9ac8b9;
  background-color: #9ac8b9;
}

.recTitleImage {
  display: flex;
  flex-direction: column;
}

.recImage {
  // width: 250px;
  height: 150px;
  border-radius: 11px;
  margin-bottom: 8px;
}

.live-label {
  padding: 5px 15px;
  margin: 0 0 7px 7px;
  background-color: white;
  font-weight: 900;
  color: black;
  border-radius: 8px;
}

hr {
  width: 100%;
  border-width: 2px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.title-progress {
  font-size: 18px;
  margin: 10px 0 15px 16px;
}

.date {
  margin: 20px 10px;
}

.day {
  color: gray;
  font-size: 14px;
}

.this-week-week {
  margin: 10px;
}

.info-type {
  font-size: 16px;
  font-weight: bold;
  color: #828282;
}

.info-type-value {
  font-size: 28px;
  font-weight: 1000;
}

.info-type-icon {
  float: right;
}

.total_scheduled {
  font-size: 16px;
}

@media only screen and (min-width: 1400px) {
  .status-container-week {
    font-size: 16px;
  }
  .day,
  .date {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1750px) {
  .grid-container {
    grid-template-rows: 1fr 0.5fr 0.7fr 0.7fr;
  }
  .this-week-week {
    margin: 50px 30px;
  }

  .day,
  .date {
    font-size: 22px;
  }

  .status-container-week {
    font-size: 18px;
  }

  h3.program-rec-header a {
    font-size: 24px;
  }
  .information-container__program-detail {
    margin: 30px 5px;
    font-size: 14px;
    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }
}
