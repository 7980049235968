#footer {
  background-color: black;
  bottom: 0;
  width: 100%;
  /* position: static !important; */
  height: 200px;
}

/* #fix-to-bottom {
  background-color: black;
  bottom: 0;
  /* position: fixed; */
/* width: 100%; */
/* } */

.custom-footer-text {
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-family: coco-gothic-regular;
  font-size: 14px;
}

.contact-text {
  color: white;
}

.Footer-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translate(0, -50%);
}

#c1 {
  width: 30%;
  float: left;
  background-color: black;
  text-align: right;
  padding: 30px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.verticalbars-whitelabel {
  height: 100%;
  border-left: 2px solid white;
  border-right: 2px solid white;
  margin: 20px 0;
}

.whiteLabel-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.top-col {
  width: 100%;
}

.bot-col {
  background-color: black;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.left-col {
  float: left;
  margin: 25px 75px;
}
.right-col {
  float: right;
  margin: 25px 75px;
}

.privacy {
  margin-bottom: 15px;
}

#c2 {
  width: 40%;
  float: left;
  background-color: black;
  line-height: 20px;
}

#c3 {
  float: left;
  width: 15%;
  background-color: black;
  text-align: left;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#c4 {
  float: left;
  width: 15%;
  background-color: black;
  padding-top: 30px;
  margin-bottom: 10px;
}

.verticalbars {
  margin-top: 10px;
  border-left: 2px solid white;
  border-right: 2px solid white;
}

.emailinput {
  font-family: futura-book, sans-serif;
  padding: 5px;
  font-size: 14px;
  width: 300px;
  height: 40px;
  background-color: black;
  border-color: white;
  border-width: medium;
  border-style: solid;
  outline: none;
}

@media only screen and (max-height: 1600px) {
  #footer {
    position: sticky;
  }
}

@media only screen and (max-width: 850px) {
  #footer {
    position: sticky;
    /* margin-top: 15vh */
  }
  .Footer-container {
    flex-direction: column-reverse;
    top: 0;
    transform: translate(0, 0);
  }
  .validate {
    text-align: center;
  }

  #c2 {
    float: none;
    width: 100%;
    padding: 10px;
    line-height: normal;
  }
  .verticalbars {
    border: none;
  }
  p.custom-footer-text {
    line-height: 2;
  }
  #c1 {
    /* margin-bottom: 20px; */
  }
  .privacy {
    margin-top: 5px;
    margin-bottom: 0px;
  }

  #c4 {
    margin-top: 10px;
  }

  #c1,
  #c3,
  #c4 {
    float: none;
    width: 100%;
    /* height: 50px; */
    /* padding: 10px; */
    text-align: center;
  }
  /* whitelabel version */
  .verticalbars-whitelabel {
    border: none;
    margin-top: 0;
  }
  .left-col {
    float: none;
  }
  .right-col {
    float: none;
  }
}

@media only screen and (max-height: 1600px) {
  #footer {
    position: relative;
  }
}
