@media (min-width: 768px) {
  .js-home-hero-container {
    height: calc(100vh - 6.880992vw);/*calc(100vh - 5.8565vw);/*100vh;*/
    min-height: 700px;
  }

  .js-home-hero-text {
    padding-left: 9.224vw;
    padding-right: 31.11273vw;
    padding-bottom: 8vw;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .js-home-hero-devices-img {
    width: 46.19326vw;
  }

  .js-home-hero-row {
    padding-top: 2.269vw;
    padding-left: 9.44vw;
    padding-right: 5.85vw;
    padding-bottom: 5.9297vw;
  }

  .js-home-hero-cta-description {
    line-height: 3.29428vw;
  }

  .js-home-hero-cta-button {
    margin-top: 2vw;
  }
}

.js-home-hero-text {
  position: absolute;
  z-index: 4;
}

@media (max-width: 767px) {
  .js-home-hero-row {
    padding-top: 14.49275vw;
  }

  .js-home-hero-text {
    padding-left: 6.28019vw;
    padding-top: 42.512vw;
    padding-bottom: 5.0724vw;
    height: 25%;
    width: 100%;
    bottom: 0;
    left: 0;
  }

  .js-home-hero-row {
    padding-bottom: 10.628vw;
  }

  .js-home-hero-cta-description {
    line-height: 8.454106vw;
    text-align: center;
    padding-left: 6.76328vw;
    padding-right: 6.76328vw;
  }

  .js-home-hero-container {
    height: calc(100vh - 5.8565vw);/*100vh;*/
  }

  .js-home-hero-devices-img {
    width: 86.4734299vw;
    margin-bottom: 6.76328vw;
  }

  .js-home-hero-cta-button {
    margin-top: 12.07729vw;
  }
}

.js-home-hero-video {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.js-home-hero-container {
  position: relative;
}
