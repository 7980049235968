@media (min-width: 768px) {
  .js-home-people-title {
    margin-bottom: 3.58711vw;
    white-space: nowrap;
  }

  .js-home-people-container {
    padding-top: 3.367496vw;
    padding-bottom: 4.34131vw;
    /*padding-left: 15.959vw;
    padding-right: 15.959vw;*/
  }

  .js-home-people-dots {
    margin-top: 0.5124vw;
  }

  .js-people-quotes {
    height: 40vw;
    width: 100%;
    padding-left: 11.35416vw;
    padding-right: 11.35416vw;
  }
}

@media (max-width: 767px) {


  .js-home-people-title {
    margin-bottom: 6vw;
    text-align: center;
  }

  .js-home-people-container {
    padding-top: 11.11111vw;
    padding-bottom: 11.11111vw;
    padding-left: 5.297vw;
    padding-right: 5.297vw;
  }

  .js-people-quotes {
    height: 136.2898vw;/*126.289vw*/
    width: 100%;
    padding-left: 11.35416vw;
    padding-right: 11.35416vw;
  }
}

.js-home-people-container {
  position: relative;
  overflow-x: hidden;
}

.js-people-quotes {
  transition-property: transform;
  transition-duration: .25s;
  transition-timing-function: ease-out;
  will-change: transform;
}
.js-people-quote:hover {
  cursor: grab;

}

.js-people-quotes:active {
  cursor: grabbing;
}
