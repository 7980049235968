.js-home-social-image {
  object-fit: cover;
  border-radius: 5px;
}
@media (min-width: 768px) {
  .js-home-social-container {
    padding-top: 8.49194vw;
    padding-left: 7.17423vw;
    padding-right: 7.17423vw;
  }

  .js-home-social-gallery {
    width: 100%;
  }

  .js-home-social-image {
    width: 27.0863vw;
  }

  .js-home-social-header {
    margin-bottom: 7.320644vw;
  }

  .js-home-social-gallery-column > *:first-child {
    margin-bottom: 2.342vw;
  }

  .js-home-social-dots {
    margin-top: 5.4172vw;
  }
}

@media (max-width: 767px) {
  .js-home-social-container {
    padding-top: 8.49194vw;
    padding-left: 7.17423vw;
    padding-right: 7.17423vw;
  }

  .js-home-social-gallery {
    width: 100%;
  }

  .js-home-social-image {
    width: 27.0863vw;
  }

  .js-home-social-header {
    margin-bottom: 7.320644vw;
  }

  .js-home-social-gallery-column > *:first-child {
    margin-bottom: 2.342vw;
  }

  .js-home-social-dots {
    margin-top: 5.4172vw;
  }
}
