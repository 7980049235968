.js-navbar-container {
  width: 100%;
  position: fixed;
  z-index: 10;
  background-color: white;
  height: 71px;
  box-shadow: -3px -1px 18px 6px #928f8f;
}
@media (min-width: 768px) {
  .js-navbar-logo {
    width: 12.448vw;
    margin-right: 10.373vw;
  }

  .js-navbar-container {
    padding-top: 1.42825vw;
    padding-bottom: 1.42825vw;
    padding-left: 2.92825vw;
    padding-right: 2.92825vw;
  }

  .js-navbar-links > *:not(:last-child) {
    margin-right: 3vw;
  }

  .js-navbar-links {
    margin-right: 5vw;
  }

  .js-navbar-container-mb {
    display: none;
  }
}

@media (max-width: 767px) {
  .js-navbar-signup-btn-mb {
    margin-right: 2vw;
  }
  .js-navbar-mb {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }
  .js-navbar-menu-mb {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 17vw;
    z-index: 5;
    background-color: white;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 10vw;
  }
  .js-navbar-container {
    display: none !important;
  }
  .js-navbar-logo {
    width: 35.448vw;
  }

  .js-navbar-container-mb {
    padding-top: 4vw;
    padding-bottom: 4vw;
    padding-left: 2vw;
    padding-right: 2vw;
    top: 0;
    position: fixed;
    width: 100%;
    z-index: 5;
    background-color: white;
  }

  .js-navbar-hamburger {
    cursor: pointer;
  }

  .js-navbar-menu-mb > *:not(:last-child) {
    margin-bottom: 3vw;
  }

  .js-navbar-menu-mb > * {
    font-size: 6vw;
    color: black;
  }
}
