.explore-studio-container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider2 {
  display: flex;
  flex-direction: row;
  background: #e4e1e1;
  width: fit-content;
  justify-content: center;
}

.explore-studio-slider2 {
  display: flex;
  flex-direction: row;
  list-style: none;
  height: 121px;
}

.explore-studio-logo2 {
  margin-top: 5px;
  width: 150px;
  height: 108px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#inactive-logo:hover img {
  -webkit-filter: invert(100%);
}

.explore-studios-bari2 {
  height: 7.5vw;
  width: 40%;
}
.explore-studios-bkpilates2 {
  height: 10vw;
  width: 40%;
}

.explore-studios-intensati2 {
  height: 23%;
  width: 80%;
}

.explore-studios-mindfresh2 {
  height: 4vw;
  width: 92%;
}

.explore-studios-masterskya2 {
  height: 8vw;
  width: 77%;
}

.explore-studios-modelfit2 {
  height: 5vw;
  width: 85%;
}

.explore-studios-skyting2 {
  height: 8vw;
  width: 78%;
}

.explore-studios-solace2 {
  height: 4vw;
  width: 63%;
}

.explore-studios-switch2 {
  height: 10vw;
  width: 38%;
}

.explore-studios-trooper2 {
  height: 5vw;
  width: 75%;
}

.explore-studios-woom2 {
  height: 5vw;
  width: 88%;
}

.explore-studios-xtendbarre2 {
  height: 5vw;
  width: 88%;
}

.explore-studios-twf2 {
  height: 9vw;
  width: 80%;
}

.explore-studios-bodyrok2 {
  height: 5vw;
  width: 88%;
}

.explore-studios-body-space2 {
  height: 12vw;
  width: 90%;
}
.explore-studios-salt-black {
  height: 6vw;
  width: 90%;
}

.explore-studios-the-studio2 {
  height: 8vw;
  width: 95%;
}

.explore-studios-hit-house2 {
  height: 6vw;
  width: 80%;
}

.explore-studios-MNT2 {
  height: 5vw;
  width: 80%;
}

.explore-studios-dtm2 {
  height: 8vw;
  width: 80%;
}
.explore-studios-mama {
  height: 100%;
  width: 100%;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.slide-container {
  overflow-x: scroll;
  width: 100vw;
}

::-webkit-scrollbar {
  width: 0px; /* remove scrollbar space */
  background: transparent; /* optional: just make scrollbar invisible */
}
/* optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #9ac8b9;
}
