.js-background-EE8373 {
  background-color: #EE8373;
}

.js-background-white {
  background-color: #FFFFFF;
}

.js-background-black {
  background-color: #000000;
}

.js-background-9AC8B9-50 {
  background-color: rgba(154,200,185, .5);
}

.js-background-gray {
  background-color: rgba(0,0,0, .08);
}

.js-background-transparent {
  background-color: transparent;
}

.js-background-ECECEC {
  background-color: #ECECEC;
}

.js-background-9AC8B9 {
  background-color: #9AC8B9;
}

.js-background-1F3830 {
  background-color: #1F3830;
}
