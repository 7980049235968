.slider-instance > .upcoming {
  border: 2px solid #9ac7b9;
  border-radius: 50px;
  padding: 0.2em 0.9em;
  width: 100px;
  color: #9ac7b9;
  justify-content: center;
}

.slider-instance {
  padding-top: 10px;
  justify-content: center;
  width: 100%;
}

.calendar-container .slick-list {
  top: 30%;
}

.slider-instance > .title {
  font-size: 1.2em;
  font-weight: bold;
}

.slider-instance > .time {
  color: #9ac7b9;
  margin: 0.5em 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
}

.slider-instance > hr {
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}
