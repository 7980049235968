.day-wrapper > .workouts-wrapper {
  background-color: white;
  border-radius: 4px;
  width: 70%;
  color: rgb(128, 128, 126);
  box-shadow: 0px 1px 12px 1px #e7e9ed;
}

.workouts-wrapper > .workout {
  display: grid;
  grid-template-columns: 13fr 1fr;
  border-top: 1px solid #d3d3d39e;
  padding-top: 12px;
  padding-bottom: 12px;
  padding: 10px;
}

.workouts-wrapper > .workout:first-child {
  border-top: 0px solid white;
}

.workouts-wrapper > .workout:hover {
  color: white;
  background-color: rgb(154, 200, 185);
}

.workouts-wrapper > .workout > div:last-child {
  display: flex;
}

#workout-status {
  display: flex;
  align-items: center;
}

.workouts-wrapper > .workout > .title-wrapper {
  padding-right: 1em;
}

.workouts-wrapper > * .delete-workout {
  background-image: url("../../images/icons/ic_popup_close@2x.png");
  width: 14px;
  height: 14px;
  background-position: center;
  margin-right: 20px;
  border-radius: 7px;
}

.workouts-wrapper > .workout > div:first-child {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.workout > div {
  display: flex;
  align-items: center;
}

.schedule-wrapper > .day-wrapper {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.schedule-master > .schedule-wrapper {
  width: 95%;
  margin: auto;
  border-left: 4px solid rgba(154, 200, 185, 0.33);
}

.day-wrapper > div:first-child {
  display: flex;
  justify-content: space-between;
  width: 170px;
  margin-left: -12px;
}

.day-wrapper > * .day-circle {
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: rgb(154, 200, 185);
}

.calendar-grid-container > * .status {
  height: 24px;
  width: 24px;
  border-radius: 12px;
  color: white;
  line-height: 24px;
  text-align: center;
  font-size: 18px;
  margin-right: 20px;
}

.calendar-grid-container > * .status.scheduled {
  border: 3px solid #9ac8b9;
}

.calendar-grid-container > * .status.completed {
  background-color: rgb(154, 200, 185);
}

.calendar-grid-container > * .status.skipped {
  background-color: #7d92a5;
}

.day-wrapper > * .workout-date {
  color: rgb(101, 101, 101);
  font-size: 20px;
  margin-top: -4px;
}

#popup-bk {
  background-color: #f9fafa;
  padding: 10px 20px 5px 20px;
  border-radius: 25px;
}

.dots > img {
  height: 30px;
  margin: auto 0;
}

.workout-modal-item:hover {
  cursor: pointer;
}
