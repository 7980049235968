.global-jetsweat-modal {
  width: 30%;
  /* height: 40%; */
  min-width: 400px;
  /* min-height: 300px; */
  background-color: white;
  top: 15%;
  margin: 0 auto;
  border-radius: 10px;
  /* background-image: linear-gradient(#89cebb 30%, white 60%); */
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 10px 20px rgba(0, 0, 0, 0.04);
}
div:focus {
  outline: 0;
}

.modal-info {
  width: 35%;
  min-width: 400px;
  height: 60%;
  min-height: 400px;
  background-color: white;
  position: absolute;
  top: 15%;
  left: 30%;
  direction: flex;

  /* border: 2px solid #9ac8b9; */
}

.congrats {
  text-align: center;
  color: white;
  padding-top: 20px;
  font-size: 22px;
}

.burned-cal {
  text-align: center;
  color: white;
  font-size: 20px;
}

.social-media {
  text-align: center;
}

.textarea-sm {
  text-align: left;
  /* margin-left: 100px; */
  background-color: #ededed;
  border: none;
}

.rate-class {
  margin-top: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: grey;
}

.rate-star {
  margin-right: 1.5rem;
  color: #c4c4c4;
}

.sbm-btn {
  background-color: #89cebb;
  /* background-color: #b8d8d1; 89CEBB*/
  width: 135px;
  height: 45px;
  color: white;
  border-radius: 10px;
  font-size: 1.15rem;
  padding-top: 3px;
  margin-left: 5px;
  margin-right: 5px;
}

.sbm-btn:hover {
  background-color: #649c90;
  width: 135px;
  height: 45px;
  color: white;
  border-radius: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.skip-btn {
  background-color: #9ac8b9;
  border: 0;
  width: 135px;
  height: 45px;
  color: white;
  border-radius: 10px;
  font-size: 1.15rem;
  padding-top: 3px;
  margin-left: 5px;
  margin-right: 5px;
}

.skip-btn:hover {
  background-color: darkgray;
  width: 135px;
  height: 45px;
  color: white;
  border-radius: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.div-with-textarea-sm {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.div-with-sbm-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.div-with-sbm-btn p {
  margin-top: -0.2rem;
  font-size: 0.55rem;
}

.sm-icon {
  font-size: 25px;
  margin-left: 11px;
  margin-right: 11px;
}

.close-modal {
  border: none;
  font-size: 20px;
  margin-left: 5px;
  margin-top: -1px;
  color: white;
  background: none;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 4;
}

.div-with-continue-link {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  color: #89cebb;
  font-weight: bold;
}

.div-with-continue-link button {
  font-size: 1.1rem;
  border: none;
  background: transparent;
  color: #9ac8b9;
}

/* Mobile screen  */

@media only screen and (max-width: 480px) {
  .modal-info {
    margin: 30% 0% 10% 5%;
    width: 90%;
    position: unset;
    height: 50%;
  }
}
