.js-home-explore-tile-container {
  position: absolute;
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  will-change: transform;
  height: 100%;
}
.js-home-explore-tile-video {
  height: 50%;
}
.js-home-explore-tile-link {
  position: absolute;
  right: 0;
  /* bottom: 3%; */
  padding-right: 10px;
}

.js-home-explore-tile-description {
  height: 43.5%;
  overflow-y: overlay;
}
.js-home-explore-tile-description:hover::-webkit-scrollbar {
  width: 2px;
}
.js-home-explore-tile-description::-webkit-scrollbar-track-piece {
  margin: 20px;
  background: black;
  border-radius: 15px;
}
.js-home-explore-tile-description::-webkit-scrollbar-thumb {
  border-radius: 15px;
}

.js-explore-close-icon {
  z-index: 2;
}

.js-explore-close-icon:hover,
.js-home-explore-tile-container:hover {
  cursor: pointer;
}

.js-home-explore-tile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 768px) {
  .js-home-explore-tile-container {
    width: 28.40409vw; /*21.40409vw;*/
    min-height: 30.7467vw;
  }

  .js-home-explore-tile-description {
    padding: 2.04978vw;
  }

  /* .js-home-explore-tile-video {
    height: 15.88579vw;
  } */
  .js-home-explore-tile-description-name,
  .js-home-explore-tile-description {
    line-height: 2vw;
  }
}

@media (max-width: 767px) {
  .js-home-explore-tile-link {
    margin-top: 2vw;
  }
  .js-home-explore-tile-description::-webkit-scrollbar {
    width: 2px;
  }
  .js-home-explore-tile-container {
    width: 93.7198vw;
    height: 111.4492vw; /*min-height: 101.4492vw;*/
    position: absolute;
  }

  .js-home-explore-tile-description {
    padding: 6.521739vw;
    line-height: 6.03vw;
  }

  /* .js-home-explore-tile-video {
    height: 52.41545vw;
  } */
}

.js-home-explore-tile-video img {
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}

.js-home-explore-tile-container {
  border-radius: 9px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: white;
}

.js-home-explore-tile-description-name {
  margin-bottom: 0.75vw;
}
