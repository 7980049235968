@media (min-width: 768px) {
  .js-textfield {
    width: 22.15568vw;
    height: 4.41616vw;
  }
}


@media (max-width: 767px) {
  .js-textfield {
    width: 71.49758vw;
    height: 14.2512vw;
    padding-left: 7.24637vw;
  }
}

.js-textfield {
  border-radius: 8px;
  border: none;
}
