@media (min-width: 768px) {
  .js-home-why-img-2, .js-home-why-img-3 {
    width: 31.1859vw;
  }

  .js-home-why-img-1 {
    width: 35.9428vw;
  }

  .js-home-why-container {
    padding-left: 8.931185vw;
    padding-right: 8.931185vw;
    padding-bottom: 9.8096vw;
  }

  .js-home-why-text-container-1 {
    padding-left: 7.85vw;
    padding-top: 4vw;
    text-align: right;
    text-align: end;
  }

  .js-home-why-text-container-2 {
    padding-left: 5.85651vw;
    padding-top: 10vw;
    padding-right: 8vw;
  }

  .js-home-why-text-container-3 {
    padding-top: 8vw;
    padding-left: 6.85651vw;
    padding-right: 5.85651vw;
    text-align: right;
    text-align: end;
  }

  .js-home-why-text {
    line-height: 2.56vw;
  }

  .js-home-why-header {
    line-height: 3.29428vw;
  }

  .js-home-why-header-1 {
    white-space: nowrap;
  }

  .js-home-why-cta-description {
    padding-left: 15.83748vw;
    padding-right: 15.83748vw;
    margin-top: 7.39385vw;
    margin-bottom: 3.7335vw;
    line-height: 4.02635vw;
  }

  .js-home-why-title {
    margin-top: 6vw;
    margin-bottom: 6vw;
  }
}

@media (max-width: 767px) {
  .js-home-why-swiper {
    padding-top: 75vw;
    padding-bottom: 85vw;
    transition-property: transform;
    transition-duration: .25s;
    transition-timing-function: ease-out;
  }

  .js-home-why-combo-container {
    position: absolute;
    width: 83.574879vw;
  }
  .js-home-why-container {
    padding-bottom: 21.9806vw;
  }

  .js-home-why-cta-description {
    margin-top: 11.8357vw;
    margin-bottom: 9.6618vw;
  }

  .js-home-why-img-1, .js-home-why-img-2, .js-home-why-img-3 {
    height: 69.32367vw;
    margin-bottom: 9.26328vw;
  }

  .js-home-why-text-container-1,
  .js-home-why-text-container-2,
  .js-home-why-text-container-3 {
    padding-left: 8.21256vw;
    padding-right: 8.21256vw;
    text-align: center;
    line-height: 7.24637vw;
  }

  .js-home-why-title {
    margin-bottom: 11.3526vw;
  }

  .js-home-why-header {
    margin-bottom: 2vw;
  }
}

.js-home-why-container {
  overflow-x: hidden;
}
