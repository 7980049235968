.js-home-explore-button-row > *:not(:last-child){
  margin-right: 1.537vw;
}

.js-home-explore-button-rows > *:not(:last-child) {
  margin-bottom: 1.2445vw;
}

.js-home-explore-container {
  position: relative;
  z-index: 2;
}

.js-home-explore-studios {
  z-index: 1;
  position: relative;
  width: 100%;
  transition-property: transform;
  transition-duration: .3s;
  transition-timing-function: ease-out;
  will-change: transform;
}

.js-explore-modal-background {
  background-color: transparent;
}

.js_wistia_responsive_wrapper {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0vw;
  width: 100%;
}


@media (min-width: 768px) {
  .js_wistia_responsive_wrapper {
    padding: 5%;
    top: 0vw;
  }

  .js-home-explore-select {
    display: none;
  }

  .js-home-explore-dots {
    margin-bottom: 4.39238vw;
  }

  .js-home-explore-container {
    padding-top: 3.51390vw;
    padding-left: 2.464666vw;
    padding-right: 2.464666vw;
    padding-bottom: 9.4436vw;
  }
  .js-home-explore-header {
    margin-bottom: 5.7101vw;
  }

  .js-home-explore-button-row:last-child {
    margin-bottom: 6.00292vw;
  }

  .js-home-explore-studios {
    height: 32.72vw;/*41.139092vw;*/
    margin-bottom: 4.39238vw;
  }

  .js-explore-close-icon {
    top: 3vw;
    right: 3vw;
    width: 6vw;
    position: absolute;
  }
}

@media (max-width: 767px) {
  .js-explore-close-icon {
    top: 20vw;
    right: 3vw;
    width: 10vw;
    position: absolute;
  }

  .js_wistia_responsive_wrapper {
    padding: 5%;
    top: 30vw;
  }
  .js-home-explore-studios {
    transform: translateX(3.140096vw);
  }

  .js-home-explore-dots {
    margin-top: 20.14492vw;
  }

  .js-home-explore-select {
    margin-top: 8.454106280vw;
    margin-bottom: 8.454106280vw;
    border-radius: 36px;
    width: 82.60869vw;
    height: 11.352657vw;
    padding-left: 35.507246vw;
    padding-right: 4.5893719vw;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('./assets/dropdown@3x.png');
    background-repeat: no-repeat;
    background-position: 94% 4.55314vw;
    background-size: 4.32608vw;
  }
  .js-home-explore-header {
    text-align: center;
  }
  .js-home-explore-banner,
  .js-home-explore-button-rows,
  .js-explore-all-studios-btn {
    display: none !important
  }

  .js-home-explore-studios {
    height: 101.449275vw;
  }

  .js-home-explore-container {
    padding-bottom: 16.666vw;
  }

  .js-home-explore-button-row:last-child {
    margin-bottom: 6.00292vw;
  }
}
.js-home-explore-container {
  overflow-x: hidden;
}
.js-home-explore-banner {
  height: 35.5783vw;
  width: 100%;
  z-index: 1;
  position: absolute;
}

@media (min-width: 768px) {
  .js-explore-link {
    height: 4.0263vw;
    min-width: 13.9092vw;
    padding-left: 2vw;
    padding-right: 2vw;
  }
}

@media (max-width: 767px) {
  .js-explore-link {
    height: 11.35265vw;
    padding-left: 2vw;
    padding-right: 2vw;
  }
}

.js-explore-link {
  border-radius: 4px;
  border: none;
  width: fit-content;
}
