.live-video-detail-container {
  /* padding: 50px 100px 0 100px; */
  margin: 0 auto;
  max-width: 850px;
}

.dc-big-play-button {
  margin-top: 190px;
  z-index: 2;
}

.dc-spinner {
  margin-top: 190px;
}

.upcoming-livestream-classes-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-top: 1px solid #f2f6f5;
  width: 100%;
}

.upcoming-livestream-classes-list-item-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.upcoming-livestream-classes-list-item-left > * {
  margin-right: 15px;
}

#dacast-player {
  max-width: 850px;
  margin: 0 auto;
}

.upcoming-livestream-studio-logo {
  border-radius: 100px;
  padding: 5px;
  border: 1px solid black;
  overflow: hidden;
  min-width: 62px;
  min-height: 62px;
}

.live-video-label-overlay {
  position: "absolute";
  padding: 5px 20px;
  border-radius: 8px;
  color: white;
  font-weight: bolder;
  font-size: 20px;
  top: 20px;
  left: 20px;
  background-color: "#9ac8b9";
}

.live-stream-schedule-date-wrapper {
  border-radius: 160px;
  max-width: 64px;
  max-height: 64px;
  padding: 2px;
}

.live-stream-schedule-date-wrapper:hover {
  cursor: pointer;
}

.back-arrow-live-button {
  border: none;
  background-color: white;
}

.back-arrow-live-button:focus {
  outline: none;
}

.day-information-livestream {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-content: center;
}

.dc-common-wrapper.dc-top-bar-wrapper {
  z-index: 1;
}

.jetsweat-live-channel-container {
  margin: 10px auto;
  max-width: 850px;
}

.livestream-add-calendar:hover {
  cursor: pointer;
}

.add-to-calendar-span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.schedule-live-button {
  color: white;
  text-align: center;
  padding: 15px 60px;
  border-radius: 4px;
  font-size: 18px;
  background: #9ac8b9;
  /* margin-top: 50%; */
}

.schedule-live-button:hover {
  cursor: pointer;
}

.jetsweat-livestream-email-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.email-input-livestream-field {
  padding: 10px;
  color: black;
  border: none;
  border-radius: 4px;
  margin-bottom: 15px;
  background: rgba(113, 119, 131, 0.07);
}
.email-input-livestream-field::placeholder {
  color: #939393;
}
.email-input-livestream-field:focus {
  outline: 0;
}
