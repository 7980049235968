.container {
  display: flex;
  width: 80%;
  margin: 30px auto 0 auto;
}

.text-below-background-img {
  margin-top: 10px;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
}

.urbandaddyLogo {
  padding-top: 12px;
  height: 30px;
  width: 100px;
  padding-left: 5px;
}
.ContentContainer {
  display: block;
  background-color: white;
  overflow-x: hidden;
}

.displayWrapper {
  margin-bottom: 8%;
}

.backgroundflex {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background-color: white;
}

.Bold-maintext {
  color: rgb(87, 86, 86);
  font-size: 40px;
  padding-top: 50px;
  padding-bottom: 20px;
  width: 75%;
  margin: 0 auto 0 auto;
  font-family: coco-gothic-regular;
  text-transform: uppercase;
  font-weight: bold;
}
.Bold-subtext {
  margin-bottom: 25px;
}

.Font {
  font-size: 20px;
  font-family: coco-gothic-regular;
}

.Paragraph {
  font-size: 20px;
  padding: 15px;
  margin: 0 80px 0 80px;
}

.Video-container {
  position: relative;
  height: 100vh;
}

.My-video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  z-index: 200;
}

.Video-textcontainer {
  position: absolute;
  top: 35%;
  left: 0;
  margin: 0 auto 0 auto;
  right: 0;
  z-index: 300;
}

.Video-text:hover {
  opacity: 0.5;
  color: #609a87;
}

.Video-text {
  color: hsl(160, 23%, 49%);
  width: 228px;
  background-color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  width: 228px;
  text-decoration: none;
  padding: 8px 28px 11px 28px;
  font-family: coco-gothic-regular;
  text-align: left;
  border-radius: 6px;
}

.Boutique-fitnesseverywhere {
  color: white;
  font-size: 45px;
  font-weight: 800;
  font-family: coco-gothic-regular;
  text-transform: uppercase;
  margin-bottom: 10px;
  transform: translate(-4px);
  text-align: left;
}

.ondemand {
  max-width: 500px;
}

.Boutique-subtext {
  font-size: 18px;
  color: white;
  font-family: coco-gothic-regular;
  text-align: left;
  margin-top: 12px;
  margin-bottom: 20px;
  transform: translate(-2px);
}

a.Video-text {
  font-size: 18px;
}

.Gallery-icons {
  width: 100px;
  height: 100px;
  margin-bottom: 50px;
}

.planeicon {
  margin-top: 20px;
  width: auto;
  height: 80px;
  margin-bottom: 60px;
}

.deviceswhiteicon {
  width: auto;
  height: 120px;
  margin-bottom: 40px;
}

.phoneicon {
  width: auto;
  height: 150px;
  margin-bottom: 10px;
}

.Gallery-member {
  float: left;
  float: left;
  width: 33.33%;
  padding: 5px;
  text-align: center;
}

.Gallery {
  position: relative;
  width: 70%;
  margin: 50px auto 0 auto;
}

.wordcontainer {
  font-family: coco-gothic-regular;
  font-size: 14px;
  margin-bottom: 50px;
  padding: 0 30px;
}

.uppercase {
  text-transform: uppercase;
}

.publicity {
  margin: 0 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pub {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pub img {
  display: flex;
}
.Center {
  padding-top: 3%;
  padding-bottom: 3%;
}
.pubphoto {
  max-width: 100%;
}
.pubphotoWallst {
  max-width: 58%;
  margin-left: 10%;
}
.pubphotoFoxnews {
  max-width: 58%;
  margin-left: 5%;
}
.pubphotoWellGood {
  max-width: 85%;
}
.pubphotoNYPost {
  max-width: 93%;
}
.pubphotoShape {
  max-width: 84%;
}
.pubphotoMensJournal {
  max-width: 84%;
}
.pubphotoABCNews {
  max-width: 84%;
}
.pubphotoSporteLuxe {
  max-width: 84%;
}

.classes-img-text {
  font-size: 30px;
  font-family: coco-gothic-regular;
  font-weight: bold;
  margin: 100px 20px 50px 0px;
  width: 80%;
  padding-left: 20%;
  text-align: center;
}
.classes-img-subtext {
  font-size: 19px;
  font-family: coco-gothic-regular;
  margin: 20px 20px 10px 0px;
  width: 80%;
  padding-left: 20%;
}

.class-icons-subtext {
  margin: 0px;
  font-size: 15px;
  font-family: coco-gothic-regular;
  font-weight: bold;
  color: grey;
  float: left;
  position: absolute;
  bottom: 1px;
  margin-bottom: 10px;
}
.img-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  padding-left: 20%;
  padding-right: 20%;
}
.first-row {
  width: 80%;
  float: left;
}

.image-1-1 {
  float: left;
  position: relative;
  height: 186px;
  grid-column: 1 / 3;
  background-image: url('../images/classes_images/Pilates Classes online.jpg');
  background-size: 98% 75%;
  background-repeat: no-repeat;
  font-size: 15px;
}

.image-1-2 {
  float: left;
  position: relative;
  grid-column: 3 / 4;
  background-image: url('../images/classes_images/Sculpt Classes online.jpg');
  background-size: 95% 75%;
  background-repeat: no-repeat;
}

.image-1-3 {
  float: left;
  position: relative;
  grid-column: 4 / 5;
  background-image: url('../images/classes_images/Barre classes online.jpg');
  background-size: 95% 75%;
  background-repeat: no-repeat;
}

.image-1-4 {
  float: left;
  position: relative;
  grid-column: 5 / 6;
  background-image: url('../images/classes_images/Stretching classes online.jpg');
  background-size: 95% 75%;
  background-repeat: no-repeat;
}
.second-row {
  width: 80%;
  float: left;
}

.image-2-1 {
  float: left;
  position: relative;
  grid-column: 1 / 2;
  background-image: url('../images/classes_images/Cardio classes online.jpg');
  background-size: 95% 75%;
  background-repeat: no-repeat;
}

.image-2-2 {
  float: left;
  position: relative;
  grid-column: 2 / 3;
  background-image: url('../images/classes_images/Mobility Classes online.jpg');
  background-size: 95% 75%;
  background-repeat: no-repeat;
}

.image-2-3 {
  grid-row: 2 / 4;
  position: relative;
  grid-column: 3 / 4;
  background-image: url('../images/classes_images/Martial Arts Classes online.jpg');
  background-size: 95% 85%;
  background-repeat: no-repeat;
}

.image-2-4 {
  float: left;
  position: relative;
  grid-column: 4 / 6;
  background-image: url('../images/classes_images/Meditation classes online.jpg');
  background-size: 95% 75%;
  background-repeat: no-repeat;
}

.third-row {
  width: 80%;
}

.image-3-1 {
  grid-column: 1 / 2;
  position: relative;
  background-image: url('../images/classes_images/Yoga Classes online.jpg');
  background-size: 95% 75%;
  background-repeat: no-repeat;
}
.image-3-2 {
  grid-column: 2 / 3;
  position: relative;
  background-image: url('../images/classes_images/HIIT Classes online.jpg');
  background-size: 95% 75%;
  background-repeat: no-repeat;
}
.image-3-3 {
  grid-column: 4 / 5;
  position: relative;
  background-image: url('../images/classes_images/Strength classes online.jpg');
  background-size: 95% 75%;
  background-repeat: no-repeat;
}
.image-3-4 {
  grid-column: 5 / 6;
  position: relative;
  background-image: url('../images/classes_images/Dance classes online.jpg');
  background-size: 95% 75%;
  background-repeat: no-repeat;
}
.carousel {
  display: none;
}

.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

.whitefont {
  color: white;
}

.Background-image {
  /* background-image:url(../images/HomePageBG.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  height: 110vh;
  font-size: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*max-width:900px;*/
  margin: 0 auto 0 auto;
  background-position: 35% 50%;
  position: relative;
  z-index: 10;
}

.hr {
  width: 80%;
  border: 1px solid rgb(87, 86, 86);
  margin: 0 auto 0 auto;
  opacity: 0.5;
  margin-top: 20px;
}

.membersquotes {
  font-size: 30px;
  font-family: coco-gothic-regular;
  color: rgb(87, 86, 86);
  text-transform: uppercase;
  margin: 50px 20px 50px 20px;
}
.membersquotesfeatured {
  font-size: 30px;
  font-family: coco-gothic-regular;
  color: rgb(87, 86, 86);
  background-color: #e4e1e1;
  text-transform: uppercase;
  padding: 50px 20px 50px 20px;
}
.partnerstext {
  font-size: 30px;
  font-family: coco-gothic-regular;
  color: rgb(87, 86, 86);
  text-transform: uppercase;
  margin: 50px 20px 0 20px;
  text-align: center;
}

.justin {
  margin: 30px auto 20px auto;
  width: 50%;
  font-size: 20px;
  content: '';
  display: table;
  clear: both;
}

.whitefont {
  color: white;
}

.Background-image {
  background-image: url(../images/hero_images/heroimage.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 110vh;
  font-size: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*max-width:900px;*/
  margin: 0 auto 0 auto;
  background-position: 35% 50%;
  position: relative;
  z-index: 10;
}

.hr {
  width: 80%;
  border: 1px solid rgb(87, 86, 86);
  margin: 0 auto 0 auto;
  opacity: 0.5;
  margin-top: 20px;
}

.membersquotes {
  font-size: 30px;
  font-family: coco-gothic-regular;
  color: rgb(87, 86, 86);
  text-transform: uppercase;
  margin: 50px 20px 50px 20px;
}
.membersquotesfeatured {
  font-size: 30px;
  font-family: coco-gothic-regular;
  color: rgb(87, 86, 86);
  background-color: #e4e1e1;
  text-transform: uppercase;
  padding: 20px;
}
.partnerstext {
  font-size: 30px;
  font-family: coco-gothic-regular;
  color: rgb(87, 86, 86);
  text-transform: uppercase;
  margin: 50px 20px 0 20px;
  text-align: center;
}

.justin {
  margin: 30px auto 20px auto;
  width: 50%;
  font-size: 20px;
}

@font-face {
  font-family: 'gothambold';
  src: url('/src/font/Gotham-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.downloadButton {
  width: 100px;
}

.subscribe-button {
  background-color: white;
  height: 55px;
  width: 120px;
  font-family: coco-gothic-regular;
  border-color: black;
  border-width: thick;
  border-bottom-width: 12px;
}

input,
select,
textarea {
  color: grey;
}

textarea:focus,
input:focus {
  color: grey;
}

.slickphoto {
  padding: 5%;
  margin-left: 3%;
  width: 29%;
  height: 93%;
}

.slickphotoSize {
  padding: 5%;
  margin-left: 6%;
  width: 26.2%;
  height: 94%;
  padding-right: 8%;
}
.testimonial {
  margin: auto;
  width: 50%;
  font-size: 20px;
}
.testimonialJustin {
  margin: auto;
  width: 47%;
  font-size: 20px;
}

.slick-dots {
  bottom: 0;
  margin: auto;
}

.slick-dots li {
  padding: 10px;
}
.testimonial_background {
  display: flex;
  flex-direction: row;
  background-image: url('../images/testimonial_folder/bw background.png');
  background-size: 100% 100%;
  width: 100vw;
  height: 30vw;
  color: white;
}

.Bold-maintext2 {
  color: white;
  font-size: 22px;
  padding-top: 50px;
  padding-bottom: 20px;
  width: 75%;
  margin: 0 auto 0 auto;
  font-family: coco-gothic-regular;
  text-transform: uppercase;
  font-weight: bold;
}

.Background-imagemid {
  background-image: linear-gradient(#89cebb 5%, white 50%);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  font-size: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*max-width:900px;*/
  margin: 0 auto 0 auto;
  background-position: 35% 50%;
  position: relative;
  z-index: 10;
}

.Box {
  /* margin-left: 54%; */
  display: flex;
  flex-direction: column;
  align-items: left;
  color: #2c2c2c;
  width: 42%;
  padding: 25px;
  z-index: 1;
  position: absolute;
  right: 0%;
}

.devicesphoto {
  width: 55%;
  height: auto;
  align-items: left;
  margin: 100px 30px 60px 40px;
  align-self: center;
}

.devicesection {
  width: 85%;
  margin: 0 auto 0 auto;
  display: flex;
}

.devicewords {
  align-items: right;
  align-self: center;
  font-family: coco-gothic-regular;
  font-size: 20px;
}

.device-sentence {
  margin-bottom: 20px;
  color: rgb(87, 86, 86);
}

.getstartedbutton {
  background-color: #9ac8b9;
  text-decoration: none;
  color: white;
  padding: 10px 30px 10px 30px;
  font-size: 15px;
}
.interface-section-1 {
  /* margin-left: 10vw; */
  margin: 7vh 2.2em 0 2.2em;
  text-align: center;
}
.interface-2-subtext {
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
}
.interface-1-subtext {
  margin-left: 15%;
  margin-right: 15%;
}
.getstartedbutton:hover {
  opacity: 0.5;
}

.Background-image-2 {
  width: 100vw;
  overflow: hidden;
}

.Background-image-2-container {
  height: 800px;
  overflow: hidden;
}

/* responsive edits for safari */
@media only screen and (min-width: 1050px) {
  .classes-img-text {
    font-size: 30px;
    font-family: coco-gothic-regular;
    font-weight: bold;
    margin: 100px 20px 50px 0px;
    width: 80%;
    padding-left: 20%;
    text-align: center;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 1050px) {
  .Background-image-2-container {
    height: 550px;
    overflow: hidden;
  }
}

.carousel-center-subtext-1 {
  display: block;
  font-size: 20px;
  padding-bottom: 20px;
  margin-left: 25%;
  margin-right: 25%;
}
.carousel-center-subtext-2 {
  font-size: 20px;
  padding-bottom: 20px;
}
.carousel-center-subtext-3 {
  font-size: 20px;
  padding-bottom: 20px;
  display: block;
  margin-left: 20%;
  margin-right: 20%;
}

@media only screen and (max-width: 850px) {
  .Gallery-member {
    width: 100%;
  }

  .devicesection {
    flex-direction: column;
  }

  .devicesphoto {
    width: 70%;
    margin: 50px;
  }

  .devicewords {
    text-align: center;
    margin-bottom: 50px;
  }
  /* .interface-2-subtext {
    text-align: unset;
    margin-left: 0%;
  } */
  /* .interface-section-1 { */
  /* text-align: unset;
    margin-left: 20vw; */
  /* } */
  /* .interface-1-subtext { */
  /* margin-left: 0%; */
  /* } */
}

.display-interfaces-2 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
}

.interface-section-2 {
  text-align-last: center;
  margin-top: 7vw;
}

@media only screen and (max-width: 700px) {
  .devicewords {
    font-size: 15px;
  }

  .getstartedbutton {
    font-size: 12px;
  }

  .Box {
    margin: auto;
    width: 90%;
    right: auto;
  }

  .Box > * {
    text-align: center;
  }

  .Heading-underline {
    margin: auto;
  }

  .Boutique-subtext,
  a.Video-text {
    font-size: 4vw;
  }

  .Boutique-fitnesseverywhere {
    font-size: 27px;
    margin-bottom: 5px;
  }

  .display-interfaces-1 {
    display: flex;
    flex-direction: column-reverse;
    width: 80%;
    margin: 0 auto;
    background-color: #efefef;
  }

  .display-interfaces-2 {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    width: 80%;
    margin: 0 auto;
  }

  .Background-image-2-container {
    height: 341px;
    overflow: hidden;
  }
}

.compatibledeviceslink {
  color: #9ac8b9;
  text-decoration: none;
  font-size: 15px;
}

.compatibledeviceslink:hover {
  opacity: 0.5;
}

.Heading-underline {
  border-bottom: 2px solid white;
  width: 38px;
  height: 0;
}

.display-interfaces-1 {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  background-color: #efefef;
}

.display-interfaces-2 {
  display: flex;
  width: 80%;
  margin: 0 auto;
}

.interface-1 {
  width: 410px;
  height: 100%;
  /* height: 340px; */
  /* margin-left: 10vw; */
  padding-top: 1vw;
  padding-bottom: 1vw;
}

.interface-1-section-1 p {
  padding-right: 18%;
}

.interface-2 {
  width: 330px;
  height: 240px;
  /* margin-left: 10vw; */
}

.interface-1,
.interface-2 {
  margin: 0 auto;
}

@media only screen and (min-width: 1000px) {
  .interface-1 {
    width: 410px;
    /* height: 320px; */
    /* margin-left: 10vw; */
    margin: 0 0.25em;
  }

  .interface-2 {
    width: 410px;
    height: 320px;
    margin-left: 10vw;
  }

  .text-container {
    padding-bottom: 100px;
  }
}

.key-icon {
  /* justify-content: center; */
  width: 45px;
}

.carousel-pilates-background {
  display: flex;
  flex-direction: row;
  background-image: url('../images/classes_images/Pilates Classes online.jpg');
  background-position: center;
  background-repeat: no-repeat;
  width: 80%;
  margin-left: 10%;
  height: 39vw;
  color: white;
}
.carousel-sculpt-background {
  display: flex;
  flex-direction: row;
  background-image: url('../images/classes_images/Sculpt Classes online.jpg');
  background-position: center;
  background-repeat: no-repeat;
  width: 80%;
  margin-left: 10%;
  height: 39vw;
  color: white;
}
.carousel-barre-background {
  display: flex;
  flex-direction: row;
  background-image: url('../images/classes_images/Barre classes online.jpg');
  background-position: center;
  background-repeat: no-repeat;
  width: 80%;
  margin-left: 10%;
  height: 39vw;
  color: white;
}
.carousel-stretching-background {
  display: flex;
  flex-direction: row;
  background-image: url('../images/classes_images/Stretching classes online.jpg');
  background-position: center;
  background-repeat: no-repeat;
  width: 80%;
  margin-left: 10%;
  height: 39vw;
  color: white;
}
.carousel-cardio-background {
  display: flex;
  flex-direction: row;
  background-image: url('../images/classes_images/Cardio classes online.jpg');
  background-position: center;
  background-repeat: no-repeat;
  width: 80%;
  margin-left: 10%;
  height: 39vw;
  color: white;
}
.carousel-mobility-background {
  display: flex;
  flex-direction: row;
  background-image: url('../images/classes_images/Mobility Classes online.jpg');
  background-position: center;
  background-repeat: no-repeat;
  width: 80%;
  margin-left: 10%;
  height: 39vw;
  color: white;
}
.carousel-martial_arts-background {
  display: flex;
  flex-direction: row;
  background-image: url('../images/classes_images/Martial Arts Classes online.jpg');
  background-position: center;
  background-repeat: no-repeat;
  width: 80%;
  margin-left: 10%;
  height: 39vw;
  color: white;
}
.carousel-meditation-background {
  display: flex;
  flex-direction: row;
  background-image: url('../images/classes_images/Meditation classes online.jpg');
  background-position: center;
  background-repeat: no-repeat;
  width: 80%;
  margin-left: 10%;
  height: 39vw;
  color: white;
}
.carousel-yoga-background {
  display: flex;
  flex-direction: row;
  background-image: url('../images/classes_images/Yoga Classes online.jpg');
  background-position: center;
  background-repeat: no-repeat;
  width: 80%;
  margin-left: 10%;
  height: 39vw;
  color: white;
}
.carousel-hit-background {
  display: flex;
  flex-direction: row;
  background-image: url('../images/classes_images/HIIT Classes online.jpg');
  background-position: center;
  background-repeat: no-repeat;
  width: 80%;
  margin-left: 10%;
  height: 39vw;
  color: white;
}
.carousel-strength-background {
  display: flex;
  flex-direction: row;
  background-image: url('../images/classes_images/Strength classes online.jpg');
  background-position: center;
  background-repeat: no-repeat;
  height: 39vw;
  color: white;
  width: 80%;
  margin-left: 10%;
}
.carousel-dance-background {
  display: flex;
  flex-direction: row;
  background-image: url('../images/classes_images/Dance classes online.jpg');
  background-position: center;
  background-repeat: no-repeat;
  height: 39vw;
  color: white;
  width: 80%;
  margin-left: 10%;
}

.carousel-classes {
  background-color: white;
  color: black;
  opacity: 0.8;
  text-align: center;
  width: 40vw;
  padding-top: 2vw;
  padding-bottom: 2vw;
}
.carousel-classes-pilates {
  background-color: white;
  color: black;
  opacity: 0.8;
  text-align: center;
  width: 40vw;
  padding-top: 2vw;
  padding-bottom: 2vw;
  margin-left: 50px;
}

.testimonial-classes {
  margin: auto;
}
.carousel-center {
  width: 100vw;
  align-content: center;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  min-height: 204px;
}

.tools-for-you {
  text-align: center;
  color: rgb(163, 210, 195);
}
.tools-for-you-subtext {
  text-align: center;
  font-size: 19px;
}
.slick-dots li.slick-active button:before {
  color: rgb(82, 82, 82);
  font-size: 12px;
}
.slick-dots li button:before {
  color: rgb(82, 82, 82);
  font-size: 12px;
}

@media only screen and (max-width: 800px) {
  /**********************************/
  /***************** boutique text *****************/
  /**********************************/
  .Boutique-fitnesseverywhere {
    font-size: 22px;
    margin-bottom: 5px;
  }

  /* .Boutique-subtext { */
  /* font-size: 10px; */
  /* margin-bottom: 0px; */
  /* } */

  .Boutique-subtext2 {
    font-size: 16px;
    margin-left: 62%;
  }

  a.Video-text {
    font-size: 14px;
    padding: 10px;
  }

  .Video-text2 {
    font-size: 12px;
    margin-left: 50%;
  }

  .Bold-maintext {
    font-size: 18px;
  }

  .membersquotes {
    font-size: 18px;
  }
  /**********************************/
  /***************** carousel *****************/
  /**********************************/

  .carousel-center {
    width: 100vw;
    align-content: center;
    text-align: center;
    font-size: 30px;
    height: inherit;
    padding: 23px;
  }

  .carousel-center-subtext {
    display: none;
  }

  .testimonial_background {
    display: flex;
    flex-direction: row;
    background-image: url('../images/testimonial_folder/bw background.png');
    background-size: 100% 100%;
    width: 100vw;
    height: 30vw;
    color: white;
  }
  .carousel .slick-dots li button:before {
    padding-top: 40px;
  }
  .testimonial {
    font-size: 16px;
  }
}

.testimonialContainer .slick-dots li button:before {
  line-height: 0px;
}

@media only screen and (max-width: 600px) {
  .img-container {
    display: none;
  }

  .classes-img-text {
    padding-top: 10px;
    margin: 0px;
    width: 100%;
    font-size: 20px;
    padding-left: 0px;
    text-align: center;
  }
  .classes-img-subtext {
    padding-top: 10px;
    margin: 0px;
    width: 100%;
    font-size: 13px;
    padding-left: 0px;
    padding-bottom: 10px;
  }
  .carousel {
    display: inline;
  }
  .testimonial {
    font-size: 7px;
    margin: auto;
  }
  .slickphoto {
    padding: 5%;
    margin-left: 3%;
    width: 26%;
    height: 96%;
    padding-top: 8%;
  }

  .slickphotoSize {
    padding: 5%;
    margin-left: 5%;
    width: 26.4%;
    height: 83%;
    margin-top: 4%;
    padding-right: 10%;
  }

  .carousel-center-subtext-1 {
    display: none;
  }
  .carousel-center-subtext-2 {
    display: none;
  }
  .carousel-center-subtext-3 {
    display: none;
  }
  /* Publicity Photos */
  .publicity {
    margin: 0 5%;
  }
  .pub {
    width: 45%;
    height: 15vh;
  }
  .pub img {
    margin: auto;
  }
}

@media screen and (orientation: landscape) {
  .testimonial {
    font-size: 14px;
    margin: auto;
  }
}

@media screen and (orientation: landscape) and (min-width: 1300px) {
  .testimonial {
    font-size: 20px;
    margin: auto;
  }
}
@media screen and (max-width: 650px) {
  .testimonial {
    font-size: 11px;
    margin: auto;
  }
  .interface-1 {
    width: 93%;
    height: 85%;
  }
  .interface-2 {
    width: 93%;
    height: 85%;
  }
  .slide-container {
    width: 90%;
    padding-left: 4px;
  }
  .slider2 {
    height: 118px;
  }
  .Center {
    margin-top: 3em;
  }
  .Footer-container {
    margin-top: 0px;
  }
  .slick-dots {
    height: 10%;
  }
  iframe {
    width: 85%;
    padding-left: 4em;
  }
}
@media only screen and (max-width: 360px) {
  .carousel .slick-dots li {
    padding: 8px;
    width: 15px;
  }
  .testimonial {
    font-size: 6px;
  }
}

.interface-section-2 > h2,
.interface-section-1 > h2 {
  font-size: 1.5em !important;
  text-align: center;
}
