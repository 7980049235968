@media (min-width: 768px) {
  .js-home-people-person-img {
    width: 32.723vw;
    margin-right: 8.7115vw;
  }

  .js-home-people-quote-container {
    width: 26.84vw;
  }

  .js-home-people-quote {
    margin-bottom: 5.85651vw;
  }
}

@media (max-width: 767px) {
  .js-home-people-quote {
    margin-bottom: 4vw;
  }

  .js-home-people-person-img {
    width: 46.859vw;
    margin-bottom: 8.2125vw;
  }
}

.js-home-people-person-container {
  position: absolute;
  transition-property: transform;
  transition-duration: .25s;
  transition-timing-function: ease-out;
  will-change: transform;
}
