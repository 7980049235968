
.innerBox {
    border: 2px solid grey;
    width: 80%;
    margin: 0 auto;
    max-width: 600px;
    margin-top: 5vh;
    margin-bottom: 5vh;
    padding-bottom: 30px;
}

.innerBox h2 {
   background:grey;
   color:white;
   padding:10px;
   max-width: 300px;
   margin:0 auto;
   margin-top: -25px;
   text-align: center;
   border-radius: 10px;
}

.tick{
   margin-right:15px;
}

.smallerwidth{
   margin:15px 80px 0 80px;
}

.successfulpayment{
   font-family: coco-gothic-regular;
   font-size:25px;
   display:inline;
}

.confirmedfont{
    font-size:20px;
    margin:0px 0 20px 0;
}

.confirmednumbers{
   font-size:20px;
}

.paymentdone{
   margin-top:20px;
}

.confirmedcocolight{
   font-family: coco-gothic-ultralight;
   font-size:20px;
   margin-bottom: 0px;
}

.confirmdownload{
   border:none;
   background-color:transparent;
   text-decoration:none;
   color:black;
   text-align: center;
   background-color: #9ac8b9;
   padding: 6px 20px;
   opacity:0.7;
   font-size: 20px;
   font-family: coco-gothic-ultralight, sans-serif;
}

.toplatform{
   border:none;
   background-color:transparent;
   text-decoration:none;
   color:black;
   text-align: center;
   background-color: #9ac8b9;
   padding: 6px 20px;
   opacity:0.7;
   font-size: 20px;
   font-family: coco-gothic-ultralight, sans-serif;
   margin-bottom: .5em;
}

.toplatformWL{
    border:none;
    background-color:transparent;
    text-decoration:none;
    color:white;
    text-align: center;
    background-color: #9ac8b9;
    padding: 6px 20px;
    opacity:0.7;
    font-size: 20px;
    margin-bottom: .5em;
    border-radius: 10px;
 }

.lastitem{
   margin-bottom: 0px;
}

.compatibledeviceslink{
   color: #9ac8b9;
   text-decoration: none;
}



@media only screen and (max-width: 750px){
   .successfulpayment{
       font-size:20px;
   }

   .confirmedcocolight{
       font-size:18px;
   }


   .confirmedfont{
       font-size:15px;
   }

   .confirmednumbers{
       font-size:15px;
   }

   .innerBox{
       height:650px;
   }

   .confirmdownload{
       font-size:15px;
   }

   .smallerwidth{
       margin:35px 60px 0 60px;
   }


}

@media only screen and (max-width: 600px){

   .tick{
       width:7%;
   }

   .successfulpayment{
       font-size:16px;
   }

   .confirmedcocolight{
       font-size:15px;
   }


   .confirmedfont{
       font-size:12px;
   }

   .confirmednumbers{
       font-size:12px;
   }

   .innerBox{
       height:630px;
       margin-bottom: 15px;
   }

   .innerBox h2 {
       width:200px;
   }

   .smallerwidth{
       margin:5px 50px 0 50px;
   }
}

@media only screen and (max-width: 450px){
   .innerBox{
       height:570px;
   }

   .smallerwidth{
       margin:20px 30px 0 30px;
   }
}

@media only screen and (max-width: 350px){
   .innerBox{
       height:950px;
   }
    .smallerwidth{
       margin:20px 30px 0 30px;
   }

   .confirmedfont{
       margin: 150px 0 20px 0;
   }

}
