.homepage-video-container {
  height: calc(100vh - 6.880992vw);
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
}

.video-background {
  /* position: absolute; */
  width: 100%;
  object-fit: cover;
  /* check this one with Alexandra */
  height: 100%;
  margin-top: 71px;
}

.overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: black;
  opacity: 0.2;
  z-index: 1;
}

div.Box > a.Video-text {
  opacity: 0.7;
}
