@media (min-width: 768px) {
  .js-home-press-link {
      opacity: .5;
      transition-duration: .5s;
      transition-property: opacity;
  }

  .js-home-press-link:hover {
      opacity: 1;
  }
}
