.progress-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  // animation: fadeIn 1s;

  > div {
    animation: fadeIn 1s;
  }

  > button {
    animation: fadeIn 1s;
  }
}

.dropdown-container {
  display: flex;
  width: 300px;
  justify-content: space-evenly;
}

.progress-background {
  height: 100%;
  width: 100%;
  background: white;
}

.totals-container {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.noDataText {
  display: flex;
  // flex-direction: column;
  width: 500px;
  justify-content: space-around;
  // align-items: center;
  font-size: 24px;
  margin-top: -200px;
  margin-left: 40px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  75% {
    opacity: 0.75;
  }
}

@media only screen and (max-width: 800px) {
  .sidebar-item-container{
    width:60%;
  }
  .progress-background{
    width: 95%;
    margin: auto;
  }
  .progress-circles-body{
    position: fixed;
    width: 50%;
   
  }
  .circle-test{
    margin: unset !important;
    width: 90px !important;
  }
  .circle-text-container {
    width: 50%;
  }
  .progress-circles{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
  }
  .progress-container{
    justify-content: unset;
  }
  .dropdown-container{
    padding: 8%;
  }
  .totals-container {
    padding: 5%;
  }
  .progress-chart-section{
    position: relative;
    top: 60%;
  }
  .circle-text-container > span{
    border:none !important;
    // font-size: smaller;
    padding: 20px 10px 20px 10px !important;
  }
  .circle-sub-text{
    font-size: smaller;
  }
  .recharts-surface{
    margin-left: 33%;
    width: 300px !important;
    height: 330px !important;
  }
}

