@media (min-width: 768px) {
  .js-home-press-header {
    margin-bottom: 3vw;
  }

  .js-home-press-container {
    padding-top: 10.4685vw;
    padding-left: 12.811127vw;
    padding-right: 12.811127vw;
    padding-bottom: 7.3206vw;
  }

  .js-lat-logo {
    width: 13vw;
  }

  .js-home-press-logos {

  }
}

@media (max-width: 767px) {
  .js-home-press-logos {
    margin-top: 15vw;
  }

  .js-home-press-logos > * {
    margin-bottom: 10vw;
  }

  .js-home-press-logos > *:nth-child(odd) {
    margin-right: 5vw;
  }

  .js-home-press-header {
    margin-bottom: 3vw;
  }

  .js-lat-logo {
    width: 40vw;
  }

  .js-home-press-container {
    padding-top: 10vw;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 7vw;
  }
}
