.sidebar-item-master {
  min-height: 80vh;
  margin-left: 250px;
  margin-bottom: 20px;
  display: grid;
  position: relative;
}

@media only screen and (max-width: 800px) {
  .sidebar-item-master {
    /*margin-left: 1vw;*/
    margin-left: 0vw;
    margin-top: 30px;
    min-width: 320px;
    max-width: 800px;
    width: calc(100vw);
  }
}

.sidebar-item-master.default {
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
}

.sidebar-item-container {
  background-color: #f2f6f5;
  border-radius: 4px;
  height: 100%;
  width: 98%;
  margin-left: 1%;
}

.sidebar-item-container > * p {
  font-family: coco-gothic-regular;
}

.buffer {
  position: absolute;
  width: 100%;
  top: 84px;
  /* margin-left: calc(4% + 205px); */
  background-color: #f3f6f5;
}

.pure-g {
  margin-top: 10px;
}
