.promo-banner {
  color: white;
  background-color: black;
  width: 100%;
  text-align: center;
  padding: 10px 0px;
  margin-top: 72px;
}

.promo-title {
  /* Only used for larger upper text */
  font-size: 1.6rem;
}

.promo-message {
  font-size: 1.2rem;
}

.promo-link {
  color: white;
  text-decoration: underline;
}

.promo-link:hover {
  color: rgb(163, 210, 195);
}

.redeem-code {
  display: inline;
}

.promo-code {
  color: rgb(163, 210, 195);
  display: inline;
  font-weight: 800;
}

@media (max-width: 800px) {
  .promo-message {
    font-size: .7rem;
  }

  .redeem-code {
    display: block;
  }
}