body {
  margin: 0;
  padding: 0;
  font-family: 'Coco Gothic', sans-serif;
  font-size: 15px;
  color: #333;
  
}

.pure-g [class *= "pure-u"] {
  /* Set your content font stack here: */
  font-family: 'Coco Gothic', sans-serif;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.green {
  color: #9AC8B9;
}

.text-muted {
  color: #828282
}

hr {
  border: 0;
  border-top: 1px solid #F2F2F2;
  margin: 10px 0;
}

.icon {
  margin-right: 5px;
  margin-bottom: -2px;
}
